import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: "adforge-2b86f.firebaseapp.com",
  projectId: "adforge-2b86f",
  storageBucket: "adforge-2b86f.appspot.com",
  messagingSenderId: "357095162168",
  appId: "1:357095162168:web:f11be31ecc624fafa041cd",
  measurementId: "G-PH3X1Q8205"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
