import React,{useState} from "react";

import Faq from "./Faq";
import FeaturesDetailed from "./FeaturesDetailed";
import FeaturesGrid from "./FeaturesGrid";
import Footer from "./Footer";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import Modal from '../utils/Modal'
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";

function Landing() {

  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  
  return (
    <main className="font-['Poppins'] h-screen flex flex-col justify-between">
      <Header showModal={setShowWaitlistModal} />
      {/* <GradientCanvas /> */}
      <Hero showModal={setShowWaitlistModal}/>
      <Footer />
      <Modal open={showWaitlistModal} setOpen={setShowWaitlistModal} title="Get notified when we're launching!" desc="" showCancelButton buttonName="Notify Me"/>
    </main>
  );
}

export default Landing 
