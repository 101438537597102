import Container from "../Container";
import HeaderButtons from "./HeaderButtons";
import HeaderMobileMenu from "./HeaderMobileMenu";
import HeaderNav from "./HeaderNav";
import Logo from "../Logo";

export const NAV_ITEMS = [
  {
    title: "Home",
    href: "#home",
    description: "Learn more about what we do",
  },
  {
    href: "#waitlist",
    title: "Waitlist",
    description: "Join our waitlist to get notified when we launch",
  },
];

const Header = ({showModal}) => {
  return (
    <header id="home">
      <nav className="py-10 text-black">
        <Container xpadded>
          <div className="flex justify-between items-center">
            <Logo />
            <HeaderNav showModal={showModal}/>
            <div className="flex items-center max-lg:hidden">
              <HeaderButtons showModal={showModal} />
            </div>
            <div className="lg:hidden z-10 mt-1">
              <HeaderMobileMenu showModal={showModal}/>
            </div>
          </div>
        </Container>
      </nav>
    </header>
  );
};

export default Header;

// CHANGE BLACK HERE
