import {useNavigate} from 'react-router-dom';
const HeaderButtons = ({showModal}) => {
  const navigate = useNavigate();

  // const handleNavToOtherMarket = () => {
  //   if (isMentor) {
  //     navigate('/')
  //   } else {
  //     navigate('/mentor');
  //   }
  // }



  return (
    <div className="space-x-8 font-medium">
      
      
      {/* <button onClick={handleLogin}>Log in</button> */}
    
      <button onClick={() => showModal(true)} className="bg-logo-color text-white px-5 py-2 rounded-full backdrop-blur-lg">Join the Waitlist</button>
      </div>
  );
};

export default HeaderButtons;
