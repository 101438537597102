import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

import Landing from './components/landing/Landing'
// Imports
import React from "react";

const Root = () => {
  return (
      <>
      <Outlet/>
      </>
  )
}


const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path='/' element={<Root/>}>
            <Route index element={<Landing/>}/>
      </Route>
  )
)


// The main React App component
const App = () => {
  return (
        <RouterProvider router={router}/>
  )
};



export default App;
